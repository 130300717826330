import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import React, { Component } from 'react';

export default class UserDetailsForm extends Component {

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => {

    })
  }

  handleSubmit = () => {
    const {
        username,
        password
    } = this.state;

    this.props.login(username, password);
}

  render() {
    return <Grid textAlign='center' style={{ height: '100vh', background: 'url("https://zebyl.com/wp-content/uploads/2019/09/bizz_banner.jpg") no-repeat', backgroundSize: 'cover'}} verticalAlign='middle' >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment style={{ padding: '2rem 3rem 5rem' }}>
          <Image src='/zebyl_logo_mark.png' style={{ maxWidth: 100, margin: 'auto' }} />
          <Header as='h2' color='teal' textAlign='center'>
            {/* <Image src='/zebyl_logo_mark.png' />  */}
        Sign in to your account
      </Header>
          <Form size='large' autoComplete="off" onSubmit={this.handleSubmit}>

            <Form.Input fluid icon='user' iconPosition='left' placeholder='Username' name="username" autoComplete="off" required onChange={this.handleChange} />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              name="password"
              autoComplete="off"
              required
              onChange={this.handleChange}
            />

            <Button color='teal' fluid size='large' onClick={() => { }}>
              Login
          </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  }

}