import {extendObservable} from 'mobx';

class Store {
    constructor() {
        extendObservable(this, {
            loader: false,
            loggedIn: false,
            userData: {},
            openModal: false
        })
    }
}

export default new Store();
