// import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import React, { Component, createRef } from 'react';
import { toast } from 'react-toastify';

export default class PhoneInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = createRef();
        this.state = {
            value: props.value || '',
            oldValue: props.value
        };
    }

    componentDidMount() {
        setTimeout(() => this.inputRef.current.focus());
    }

    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
        return this.state.value;
    }

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    }

    // Gets called once when editing is finished (eg if enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd = () => {
        // our editor will reject any value if returned true

        const phonePattern = new RegExp('^[0-9]{10}$')
        const { value, oldValue } = this.state;

        if (oldValue === value) return true;

        if (phonePattern.test(value)) return false;
        else {
            toast.error('Contact Number is not valid. It should be 10 digit long.');
            return true
        }
    }

    render() {
        return (
            <input ref={this.inputRef}
                value={this.state.value}
                onChange={event => this.setState({ value: event.target.value })}
                style={{ width: "100%" }}
                className="ag-input-field-input ag-text-field-input"
            />
        );
    }
}