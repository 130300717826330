import React from 'react';
import {
    Route,
    Redirect,
} from "react-router-dom";
import { checkLoggedIn } from './api_endpoints/auth';


export default class PrivateRoute extends React.Component {

    render() {
        const { component: Component, ...rest} = this.props;
        const  loggedIn  = checkLoggedIn();

        return (
            <Route {...rest}
                   render={props =>
                       loggedIn ? (
                           <Component {...props} />
                       ) : (
                           <Redirect
                               to={{
                                   pathname: "/login",
                                   state: {from: props.location}
                               }}
                           />
                       )
                   }
            />
        );
    }
}



