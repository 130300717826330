import React, {Component} from 'react';
import { inject, observer} from 'mobx-react';
import {Dimmer, Loader} from "semantic-ui-react";

// export default class CalendarGrid extends Component {
export default inject('appStore')(observer(class LoaderComponent extends Component {

    render() {

        const loader = this.props.appStore.loader;
        return (

            <Dimmer active={loader} page inverted>
                <Loader size='big'>Processing...</Loader>
            </Dimmer>
        );
    }
}));



