import React, { Component } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import CustomDateInput from './framework-components/CustomDateInput'
import EmailInput from './framework-components/EmailInput'
import PhoneInput from './framework-components/PhoneInput'
import { sendRequest, logout } from '../api_endpoints/auth'
import { DateRange } from 'react-date-range';
import {
    Input,
    Button,
    Dropdown,
    Icon,
    Image,
    Menu,
    Visibility
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { getColumnDataType } from '../utils/utils'
import NewOrder from './NewOrder';
import axios from 'axios';
import { PORTAL_SERVER } from '../constants';

const fixedMenuStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}

const menuStyle = {
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    marginBottom: '1em',
    transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

export default inject('appStore')(observer(class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.pagination = React.createRef();
        this.dateRangeRef = React.createRef();

        const params = props.params;

        const search = (params && params.job_number) || ''

        this.state = {
            data: [],
            columns: [],
            pageSize: 10,
            page: 0,
            pageCount: 0,
            search,
            dateRange: {
                startDate: null,
                endDate: null,
                key: 'selection'
            },
            open: false,
            selections: [1, 1],
            openModal: false,
            sortBy: 'createdate desc'
        }
    }

    componentDidMount() {

        this.getData();

    }

    getData() {

        const { pageSize, page, columns, sortBy, search, dateRange, selectionColumns } = this.state;

        const start = dateRange.startDate && moment(dateRange.startDate).format('YYYY-MM-DD')
        const end = dateRange.endDate && moment(dateRange.endDate).format('YYYY-MM-DD')
        sendRequest('/oms/get-order', 'POST', {
            limit: pageSize,
            offset: page * pageSize,
            sortBy: sortBy,
            columnsNeeded: columns.length === 0,
            search,
            dateRange: {
                start,
                end,
                colId: 'delivery_date'
            }
        })
            .then(response => {
                if (response && response.success) {

                    const data = response.data;

                    const columnsMetadata = data && data.columns;
                    const orders = data && data.orders;
                    const totalOrders = data && data.totalOrders;

                    const pageCount = totalOrders ? Math.ceil(totalOrders / pageSize) : 0

                    const selectionColumnsData = (data && data.selectionColumns) || selectionColumns;

                    let otherStateVariables = {}
                    if (selectionColumnsData && selectionColumnsData.length) {

                        for (const column of selectionColumnsData) {

                            if (data && data[column]) otherStateVariables[column] = data[column]
                        }
                    }

                    this.props.appStore.columns = columnsMetadata || columns;
                    this.props.appStore.selectionColumns = selectionColumnsData || selectionColumns;

                    if (Object.keys(otherStateVariables).length) {
                        this.props.appStore.otherStateVariables = otherStateVariables;
                    }

                    this.setState({
                        data: orders,
                        columns: columnsMetadata || columns,
                        totalOrders,
                        pageCount,
                        ...otherStateVariables,
                        selectionColumns: selectionColumnsData
                    })

                } else {

                }
            })
            .catch(e => {
                console.log(e)
            })

    }

    paginationChanged = ({ selected }) => {
        this.setState({
            page: selected
        }, () => {
            this.getData()
        })
    }

    onSortChanged = ({ api }) => {
        // Sort Changed.
        const sortModel = api.getSortModel();

        const sortBy = sortModel && sortModel.length && sortModel[0] && (sortModel[0].colId + ' ' + sortModel[0].sort)

        this.setState({
            sortBy
        }, () => {
            this.getData()
        })
    }

    onSearch = ({ target: input }) => {
        const self = this;

        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: input.value,
            page: 0,
            typingTimeout: setTimeout(function () {
                self.getData(self.state.name);
            }, 1000)
        });
    }

    checkPhoneNumbber = (contact_number) => {
        return axios.post(PORTAL_SERVER + '/oms/check-contact-number', {
            contact_number
        })
    }

    onCellValueChanged = (e) => {
        console.log(e);

        sendRequest('/oms/update-order', 'POST', {
            columnName: e.column.colId,
            newValue: e.newValue,
            oldValue: e.oldValue,
            rowData: e.data
        })
            .then(response => {
                if (response && response.success) {
                    toast.success(`${e.column.colDef.headerName} updated successfully`, {});
                } else {
                    const message = (response && response.data && response.data.message) || 'Something went wrong'
                    toast.info(message, {});
                }
            })
            .catch(e => {
                toast.error(`'Something went wrong`, {});
            })
    }

    onDateRangeChange = (item) => {

        const { open } = this.state

        this.setState({
            dateRange: item.selection
        }, () => {
            if (this.dateRangeRef.current.state.focusedRange.includes(1)) return
            this.getData();

            this.setState({
                open: !open,
            })
        })
    }

    saveOrder = (data) => {
        axios.post(PORTAL_SERVER + '/oms/generate_lead', {
            ...data,
            source: 'oms'
        })
            .then(response => {

                const data = response && response.data
                if (response && response.status === 200 && data.success) {
                    toast.success('Job created successfully!')
                } else {
                    toast.success('Something went wrong! Please try again later')
                }

                this.setState({
                    openModal: false
                })

                this.getData();
            })
            .catch(e => {
                toast.error('Something went wrong! Please try again later.')
            })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({
            openModal: !openModal
        })
    }

    onFirstDataRendered = (params) => {
        const gridColumnApi = params.columnApi;
        // gridColumnApi.sizeColumnsToFit()
        const allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }

    render() {

        const { columns: columnsDefs, data, pageCount, dateRange, open, search, selectionColumns, page, pageSize, totalOrders } = this.state;

        const columns = []

        columnsDefs.map(c => {

            let columnType = ''

            try {
                columnType = c.column_metadata.toLowerCase().includes('email') ? 'emailColumn' : ''

                if (!columnType) columnType = c.column_metadata.toLowerCase() === 'contact_number' ? 'phoneColumn' : ''

            } catch (e) {

            }

            return columns.push({
                header: c.column_title,
                accessor: c.column_metadata,
                type: [columnType || getColumnDataType(c.column_datatype)]
            })
        })

        const columnTypes = {
            nonEditableColumn: {
                editable: false,
                sortable: true,
            },
            dateColumn: {
                cellEditor: 'datePicker',
                sortable: true,
                valueFormatter: (params) => {
                    console.log(params)
                    return params && params.value ? moment(params.value).format('MMMM D, YYYY') : ''
                }
            },
            textColumn: {
                sortable: true
            },
            numberColumn: {
                sortable: true,
            },
            emailColumn: {
                cellEditor: 'emailInput',
                sortable: true,
            },
            phoneColumn: {
                cellEditor: 'phoneInput',
                sortable: true,
            },
        }

        const frameworkComponents = {
            datePicker: CustomDateInput,
            emailInput: EmailInput,
            phoneInput: PhoneInput
        }

        const start = dateRange.startDate && moment(dateRange.startDate).format('MMMM Do')
        const end = dateRange.endDate && moment(dateRange.endDate).format('MMMM Do')
        let text = ``
        if (start && end && start !== 'Invalid date' && end !== 'Invalid date') text = `${start} - ${end}`

        const { openModal } = this.state
        const nonEditableColumns = ['job_number', 'createdate']

        const trigger = (
            <span>
              <Image 
               src={PORTAL_SERVER + `/assets/images/profile-photos/${this.props.appStore?.userData?.profile_photo || 'no_profile.jpg'}`} 
               style={{
                   height: '50px',
                   width: '50px',
                   objectFit: 'contain',
                   borderRadius: '50%'
               }}
            //    avatar
               />
            </span>
          )
          

        return (
            <>
                <Visibility
                    onBottomPassed={this.stickTopMenu}
                    onBottomVisible={this.unStickTopMenu}
                    once={false}
                    style={{
                        backgroundColor: 'white'
                    }}
                >   
                    <div className="navbar">
                        <Menu
                            borderless
                            fixed={false ? 'top' : undefined}
                            style={false ? fixedMenuStyle : menuStyle}
                        >
                                <Menu.Item className="nav-icon">
                                    <Image size='mini' src='/zebyl_logo_mark.png' />
                                </Menu.Item>
                                <Menu.Item header>Welcome {this.props.appStore?.userData?.full_name}!</Menu.Item>

                                <Menu.Menu position='right'>
                                    {/* <Menu.Item onClick={logout} header style={{cursor: 'pointer'}}>Logout</Menu.Item> */}
                                    <Dropdown 
                                    trigger={trigger} 
                                    style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} icon={null}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                            {/* <Dropdown.Divider />
                                            <Dropdown.Header>Header Item</Dropdown.Header>
                                            <Dropdown.Item>
                                                <i className='dropdown icon' />
                                                <span className='text'>Submenu</span>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>List Item</Dropdown.Item>
                                                    <Dropdown.Item>List Item</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                        </Menu>
                    </div>
                </Visibility>

                <div className="oms-table">
                    <NewOrder saveOrder={this.saveOrder} openModal={openModal} toggleModal={this.toggleModal} />
                    <div className="filter-section">
                        {   [88, 202].includes(this.props.appStore?.userData?.company_id)  ? 
                            <Button color='teal' onClick={() => this.toggleModal()} primary className="add-new-order-button">
                                Add New Order <Icon name='add right' />
                            </Button> :
                            <div></div>

                        }   
                        <div className={'search-section'}>
                            <Input
                                name="search"
                                placeholder="Search..."
                                icon='search'
                                onChange={this.onSearch}
                                value={search || ''}
                                style={{ paddingRight: '4px' }}
                            ></Input>
                            <Button
                                primary
                                color='teal'
                                onClick={() => { this.setState({ open: !!!open }) }}
                            >{(open && 'Close') || text || 'Select Date Range'}</Button>

                            {
                                open && <div className="date-range-container"><DateRange
                                    editableDateInputs={false}
                                    onChange={this.onDateRangeChange}
                                    moveRangeOnFirstSelection={true}
                                    ranges={[dateRange]}
                                    onRangeFocusChange={(e) => { console.log(e) }}
                                    ref={this.dateRangeRef}
                                > </DateRange></div>
                            }

                            {
                                (text || search) && <Button
                                    primary
                                    onClick={() => {
                                        this.setState({
                                            search: '', dateRange: {
                                                startDate: null,
                                                endDate: null,
                                                key: 'selection'
                                            }
                                        }, () => {
                                            this.getData()
                                        })
                                    }}
                                >{'Reset'}</Button>
                            }
                        </div>



                    </div>
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            pagination={true}
                            paginationPageSize={10}
                            rowData={data}
                            ref={this.pagination}
                            columnTypes={columnTypes}
                            frameworkComponents={frameworkComponents}
                            onSortChanged={this.onSortChanged}
                            stopEditingWhenGridLosesFocus={true}
                            onFirstDataRendered={this.onFirstDataRendered}
                            suppressPaginationPanel={true}
                        >
                            {
                                columns.map((column, i) => {

                                    if (selectionColumns && selectionColumns.includes(column.accessor)) {

                                        return <AgGridColumn
                                            editable={true}
                                            headerName={column.header}
                                            field={column.accessor}
                                            onCellValueChanged={this.onCellValueChanged}
                                            // floatingFilter={true}
                                            type={column.type}
                                            cellEditor={'agSelectCellEditor'}
                                            cellEditorParams={{
                                                values: this.state[column.accessor] || []
                                            }}
                                        ></AgGridColumn>
                                    } else {

                                        return <AgGridColumn
                                            headerName={column.header}
                                            field={column.accessor}
                                            onCellValueChanged={this.onCellValueChanged}
                                            // floatingFilter={true}
                                            type={column.type}
                                            pinned={i === 0 ? 'left' : undefined}
                                            editable={!nonEditableColumns.includes(column.accessor)}
                                            sort={column.accessor === 'createdate' && "desc"}
                                        ></AgGridColumn>
                                    }
                                })
                            }
                        </AgGridReact>
                        <div className="pagination-box">
                            <div class="ag-paging-row-summary-panel" aria-hidden="true">
                                <span id="first-row" ref="lbFirstRowOnPage" class="ag-paging-row-summary-panel-number">{(page * pageSize) + 1}</span>
                                <span id="ag-698-to">to</span>
                                <span id="last-row" ref="lbLastRowOnPage" class="ag-paging-row-summary-panel-number">{(page * pageSize) + data.length}</span>
                                <span id="ag-698-of">of</span>
                                <span id="row-count" ref="lbRecordCount" class="ag-paging-row-summary-panel-number">{totalOrders}</span>
                            </div>

                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.paginationChanged}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                selected={page}
                            />
                        </div>
                    </div>
                </div></>
        );
    }
}))