import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import { makeStyles } from '@material-ui/core/styles';
import {
    Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import history from './history';
import stores from './stores';
import { Provider } from 'mobx-react';
import LoaderComponent from './components/loader';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { authenticateUsers } from './api_endpoints/auth';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './PrivateRoute'


export default class App extends Component {

    toggleLoader = () => {
        stores.store.loader = !stores.store.loader;
    }

    login = (username, password) => {
        authenticateUsers(username, password);
    }

    QueryStringToJSON() {
        var pairs = window.location.search ? window.location.search.slice(1).split('&') : [];

        var result = {};
        pairs.forEach(function (pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    constructor(props) {
        super(props);
        const queryParams = this.QueryStringToJSON();

        this.state = {
            params: queryParams
        }
    }

    // componen() {
        
    // }

    

    render() {

        // const loggedIn = checkLoggedIn();
        const { params } = this.state
        return (
            <div>
                <ToastContainer 
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Provider
                    appStore={stores.store}
                >
                    <div>
                        <LoaderComponent />
                        <Router history={history}>
                            <Switch>
                                <Redirect exact path="/" to="/dashboard" />

                                <Route path="/login">
                                    <Login toggleLoader={this.toggleLoader} login={this.login} />
                                </Route>

                                <PrivateRoute path="/dashboard" 
                                    component={(props) => <Dashboard {...props} toggleLoader={this.toggleLoader} params={params}/>}
                                />
                                
                            </Switch>
                        </Router>
                    </div>
                </Provider>
            </div>
        );
    }
}
