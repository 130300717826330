import axios from 'axios';
import { PORTAL_SERVER } from '../constants'
import stores from '../stores';
import { logout } from "../api_endpoints/auth";

axios.interceptors.response.use((response) => {
    return response
}, function (error) {

    if (!error) return

    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === PORTAL_SERVER + '/auth/token') {
        // It means refresh token is alse expired.
        logout();
        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        const refreshToken = stores.store.userData.refresh_token;

        return axios.post(PORTAL_SERVER + '/auth/token',
            {
                "refresh_token": refreshToken
            })
            .then(res => {

                if (res && res.status === 200) {
                    const respData = res.data;
                    if (respData.result === 'success') {
                        stores.store.userData = respData.data.user || {};
                        stores.store.userData.access_token = respData.data.token;
                        stores.store.userData.refresh_token = refreshToken;

                        window.localStorage.setItem('token', stores.store.userData.access_token );
                        window.localStorage.setItem(
                            'user',
                            JSON.stringify(respData.data.user || {}),
                        );

                        axios.defaults.headers.common['Authorization'] = 'ACCESS_TOKEN ' + stores.store.userData.access_token;
                        originalRequest.headers['Authorization'] = 'ACCESS_TOKEN ' + stores.store.userData.access_token;
                        return axios(originalRequest);
                    }

                }
            })
    }
    return Promise.reject(error);
});

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
}

export const callApi = (
    endpoint,
    method = 'get',
    body = {},
    isLoading = true,
    contentType = 'application/json',
    responseType = 'json'
) => {
    stores.store.loader = isLoading;
    // await checkAndRefreshAccessToken();

    const headers = {
        Accept: 'application/json',
        'Content-Type': contentType,
        Authorization: 'ACCESS_TOKEN ' + stores.store.userData.access_token,
    };

    return axios({
        method: method,
        baseURL: PORTAL_SERVER,
        url: endpoint,
        data: body,
        params: method.toLowerCase() === 'get' ? body : null,
        headers,
        responseType
    })
    .then(checkStatus)
    .then(response => {
        stores.store.loader = false;
        return response.data;
    })
    .catch(err => {
        stores.store.loader = false;
        return err.response.data;
    });
};
