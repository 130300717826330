export const getColumnDataType = (type = 'string') => {
    switch(type) {
        case 'string':
            return 'textColumn'
        case 'date':
        case 'time':
        case 'date_time':
            return 'dateColumn'
        case 'number':
            return 'numberColumn'
        default: 
            return 'textColumn'
    }
}