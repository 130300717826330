import history from '../history';
import stores from '../stores';
import { from } from "rxjs";
import { callApi } from "../middleware/middleware";
import { toast } from 'react-toastify';

async function sendRequest (url, method, data, isLoading= true, contentType = 'application/json', responseType = "json") {
    return callApi(url, method, data, isLoading, contentType, responseType);
}


function checkLoggedIn() {
    updateConfigFromSession();
    return stores.store.loggedIn;
}

function authenticateUsers(username, password) {
    from(
        sendRequest('/auth/login', 'POST', {username, password })
    ).subscribe(
        resp => {
            if (resp && resp['result'] === 'success') {
                handleUserAfterLogin(resp);
                toast.success('Logged in successfully', {});

            } else {

                toast.info(resp.message, {});
                // alert(resp.message || 'Expired');
            }
        },
        error => {
            toast.error('Something went wrong', {});
        },
    );
}


function handleUserAfterLogin(response) {

    // Check if user profile is active. Send it to appropriate screen
    storeDataInSession({
        ...response['data'],
    });
    updateConfigFromSession();
    goToDashboard();
}

function updateConfigFromSession() {
    stores.store.userData = JSON.parse(window.localStorage.getItem('user')) || {};
    stores.store.userData.access_token = window.localStorage.getItem('token');
    stores.store.userData.refresh_token = window.localStorage.getItem('refresh_token');
    if (stores.store.userData.access_token && stores.store.userData.refresh_token) {
        stores.store.loggedIn = true;
    }
}

function logout() {
    stores.store.loggedIn = false;
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('timestamp');
    window.localStorage.removeItem('user');
    stores.store.userData = {};
    history.push('/login');
}

function goToDashboard () {

    history.push('/dashboard');
}


function storeDataInSession(data) {
    window.localStorage.setItem('token', data['token']);
    window.localStorage.setItem('timestamp', data['timestamp']);
    window.localStorage.setItem(
        'refresh_token',
        data['refresh_token'],
    );
    window.localStorage.setItem(
        'user',
        JSON.stringify(data['user']),
    );
}


export {
    authenticateUsers,
    updateConfigFromSession,
    storeDataInSession,
    handleUserAfterLogin,
    logout,
    goToDashboard,
    checkLoggedIn,
    sendRequest
};
