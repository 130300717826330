import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Icon, Form } from 'semantic-ui-react';
import { getColumnDataType } from '../utils/utils'
import {
    DateInput,
} from 'semantic-ui-calendar-react';
import moment from 'moment';
import axios from 'axios';
import { PORTAL_SERVER } from '../constants';
import { toast } from 'react-toastify';

export default inject('appStore')(observer(class NewOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: []
        }
    }

    componentDidMount() {


    }

    checkJobNumber = (job_number) => {
        return axios.post(PORTAL_SERVER + '/oms/check-job-number', {
            job_number
        })
    }

    checkPhoneNumbber = (contact_number) => {
        return axios.post(PORTAL_SERVER + '/oms/check-contact-number', {
            contact_number
        })
    }

    handleChange = async (e, { name, value }) => {

        this.setState({ [name]: value }, () => {})
    }

    handleInvalidInput =(event) => {
        if (event.target.name === 'contact_number') {
            event.target.setCustomValidity('Please enter a 10-digit phone number.');
        } else if (event.target.name === 'job_number') {
            event.target.setCustomValidity('Job number format is not valid. (Valid job number example: J#04102021_A70)');
        }
    }

    handleSubmit = async () => {

        const {job_number /*, contact_number */} = this.state;

        this.props.appStore.loader = true;
        const jobNumberAvailable = (await this.checkJobNumber(job_number))?.data?.available;

        this.props.appStore.loader = false;

        if (!jobNumberAvailable) {
            return toast.info(`Job number ${job_number} is not available. Try another "A" number`)
        }

        // const contactNumberAvailable = (await this.checkPhoneNumbber(contact_number))?.data?.available;

        // if (!contactNumberAvailable) {
        //     return toast.info(`Contact number ${contact_number} already exists in our system.`)
        // }

        const { columns: columnsDefs } = this.props.appStore;
 
        const data = {}

        columnsDefs.map(column => {
            return data[column.column_metadata] = this.state[column.column_metadata]
        })

        if (!data['interest']) data['interest'] = 'Trees'

        this.props.saveOrder && this.props.saveOrder(data)

    }

    initializeFormValues = () => {
        const { columns: columnsDefs } = this.props.appStore;
 
        const data = {}

        columnsDefs.map(column => {
            return data[column.column_metadata] = null
        })

        this.setState({
            ...data
        })
    }

    render() {
        const { openModal } = this.props;
        const { columns: columnsDefs, selectionColumns, otherStateVariables } = this.props.appStore;

        if (!columnsDefs || !otherStateVariables || !selectionColumns) return <></>;
        const columns = []
        columnsDefs.map(c => {
            let columnType = ''
            try {
                // FInd phone or email column
                columnType = c.column_metadata.toLowerCase().includes('email') ? 'email' : ''
                if (!columnType) columnType = c.column_metadata.toLowerCase() === 'contact_number' ? 'phoneColumn' : ''
                if (!columnType) columnType = selectionColumns && selectionColumns.includes(c.column_metadata) ? 'selectionColumn' : ''
                
            } catch (e) {

            }

            const values = columnType === 'selectionColumn' ? otherStateVariables[c.column_metadata] : []
            return columns.push({
                header: c.column_title,
                accessor: c.column_metadata,
                columnType: columnType || getColumnDataType(c.column_datatype),
                values: values,
                value: this.state[c.column_metadata] || values[0] || (c.column_metadata === 'job_number' ? ('J#' + moment().format('MMDDYYYY') + '_A'): ('')),
                pattern: columnType === 'phoneColumn' ? "^[0-9]{10,11}$" : (c.column_metadata === 'job_number' ? "^J#[0-9]{8}_[A][0-9]+$" : ''),
                type: ['email'].includes(columnType) ? columnType : 'text'
            })
        })

        const notRequiredColumns = ['address', 'interest', 'delivery_date'];
        const notNeededColumns = ['createdate']
        return (
            <Modal
                // closeIcon={true}
                size={'small'}
                open={openModal}
                onClose={() => this.props.toggleModal()}
                onMount={() => this.initializeFormValues()}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
                <Modal.Header>
                    Add New Order

                    <Icon name='close right' onClick={() => this.props.toggleModal()}/>
                </Modal.Header>
                <Modal.Content scrolling>

                    <Modal.Description>

                        <Form autoComplete="off" onSubmit={this.handleSubmit}>

                            {columns.map(column => {

                                if (notNeededColumns.includes(column.accessor)) return <></>

                                if (['selectionColumn'].includes(column.columnType)) {
                                    return <Form.Dropdown
                                        options={column.values.map((v, i) => ({ key: ' ' + v + i, text: v, value: v }))}
                                        label={column.header}
                                        placeholder={column.header}
                                        fluid
                                        search
                                        selection
                                        name={column.accessor}
                                        value={column.value}
                                        // text={setupLocation}
                                        onChange={this.handleChange}
                                        required={!notRequiredColumns.includes(column.accessor)}
                                    />
                                } else if (['dateColumn'].includes(column.columnType)) {
                                    return <DateInput
                                        name={column.accessor}
                                        dateFormat={"YYYY-MM-DD"}
                                        placeholder={column.header}
                                        label={column.header}
                                        value={column.value}
                                        onChange={this.handleChange}
                                        // inline={true}
                                        pickerWidth={'25rem'}
                                        // disable={enable}
                                        markColor={'orange'}
                                        minDate={new Date()}
                                        closable={true}
                                        autocomplete="off"
                                        hideMobileKeyboard={true}
                                    />
                                } else if(['textColumn', 'numberColumn', 'email', 'phoneColumn'].includes(column.columnType) || true) {
                                    return <Form.Input
                                        fluid
                                        label={column.header}
                                        placeholder={column.header}
                                        name={column.accessor}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        value={column.value}
                                        onInvalid={this.handleInvalidInput}
                                        onInput={(e) => {e.target.setCustomValidity('')}}
                                        required={!notRequiredColumns.includes(column.accessor)}
                                        type={column.type}
                                        minLength={3}
                                        maxLength={100}
                                        pattern={column.pattern || null}
                                    />
                                } else {
                                    return <></>
                                }
                            })}

                            <Form.Button color='teal' primary>
                                Proceed <Icon name='chevron right' />
                            </Form.Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                {/* <Modal.Actions>
                    
                </Modal.Actions> */}
            </Modal>
        );
    }
}));
