// import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import React, { Component, createRef } from 'react';
import {
    DateInput,
} from 'semantic-ui-calendar-react';

export default class CustomDateInput extends Component {
    constructor(props) {
        super(props);
 
        this.inputRef = createRef();
        this.state = {
            value: props.value || ''
        };
    }
 
    componentDidMount() {
        // setTimeout(() => this.inputRef.current.focus());
    }
 
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
        return this.state.value;
    }
 
    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    }
 
    // Gets called once when editing is finished (eg if enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
        // our editor will reject any value if returned true
        return false;
    }

    handleDateChange = (event, {name, value}) => {

        this.setState({
            value
        }, () => {
            this.props.stopEditing()
        })
        
        // self.props.appStore.selectedDate = value;
    }
 
    render() {
        return (
            <DateInput
                ref= {this.inputRef}
                name="date"
                dateFormat={"YYYY-MM-DD"}
                placeholder="Date"
                value={new Date()}
                onChange={this.handleDateChange.bind(this)}
                inline={true}
                pickerWidth={'25rem'}
                style={{position: 'fixed'}}
                // disable={enable}
                markColor={'orange'}
                closable={true}
                popupPosition={'bottom left'}
                minDate={new Date()}
            />
        );
    }
 }